@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import './easter-eggs.css';
html {
  overflow: overlay;
  width: 100%;
  font-size: 20px;
}

body {
  width: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
}
/*
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-primary);
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary);
  border-radius: 100px;
  height: 100px;
}
*/

.focus-ring:focus {
  @apply ring-offset-primary ring-accent outline-none ring-2 ring-offset-2;
}

.focus-input {
  @apply focus:ring-offset-primary focus:ring-accent focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.focus-input-error {
  @apply focus:ring-offset-primary focus:outline-none focus:ring-2 focus:ring-[#FFE2E0] focus:ring-offset-2;
}

/* Animations */

.skeleton-available {
  background-color: hsl(var(--a));
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    hsl(var(--a));
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.skeleton {
  background-color: rgba(224, 224, 224, 0.3);
  animation: skeleton 1.5s ease-in-out infinite alternate;
}

@keyframes skeleton {
  0% {
    background-color: rgba(224, 224, 224, 0.3);
  }
  50% {
    background-color: rgba(224, 224, 224, 0.8);
  }
  100% {
    background-color: rgba(224, 224, 224, 0.3);
  }
}

.step-button-animation {
  animation: step-button-animation 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)
    forwards;
}

@keyframes step-button-animation {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.confirm-loading {
  stroke-dasharray: 32;
  stroke-dashoffset: 32;
  animation: confirm-loading-animation 30s linear infinite;
}

@keyframes confirm-loading-animation {
  0% {
    stroke-dashoffset: 32;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.inner-round {
  background: radial-gradient(
    circle at top left,
    transparent 1vw,
    darkblue 1vw
  );
}

.animated-checkmark polyline {
  stroke-dasharray: 32;
  stroke-dashoffset: 32;
  animation: confirm-loading-animation 0.4s linear 1 forwards;
}
